import { useSelector } from "react-redux";

export function hasPermission(userPermissionSet, requiredPermission) {
  return (userPermissionSet || []).some((p) => p === requiredPermission);
}

export function useHasPermission(requiredPermission) {
  const userPermissions = useSelector((s) => s.auth.permissions);
  return hasPermission(userPermissions, requiredPermission);
}

export const permissions = {
  adjustMaximumRentalsPerUser: "operator.adjustMaximumRentalsPerUser",
  bikeDefects: "operator.manageBikeDefects",
  bikeDefectsConfiguration: {
    access: "operator.getBikeDefectsConfiguration",
    modify: "operator.manageBikeDefectsConfiguration",
  },
  payoutFunds: "wallet.payoutVendorFunds",
  rides: "operator.getRides",
  deactivateUserAccount: "operator.deactivateUserAccount",
  notStaredRides: "operator.getNotStartedRides",
  clients: "operator.getClients",
  globalClients: "operator.getGlobalClients",
  relocation: "operator.accessRelocationData",
  relocationHistory: "operator.accessRelocationHistoryData",
  adjustServiceSettings: "operator.adjustServiceSettings",
  termsAndConditions: {
    access: "operator.accessTermsAndConditions",
    modify: "operator.manageTermsAndConditions",
  },
  pricingConfiguration: {
    access: "operator.accessPricingConfiguration",
    modify: "operator.managePricingConfiguration",
  },
  campaigns: "operator.campaigns",
  zones: "operator.manageZones",
  operatorAccounts: "operator.manageOperatorAccounts",
  operatorRooveeAccounts: "operator.manageOperatorRooveeAccounts",
  autoLogoutSettings: "operator.adjustAutoLogout",
  clientsActivityThresholdSettings:
    "operator.adjustClientsActivityThresholdSettings",
  auditLog: "operator.accessAuditLog",
  manageParentalConsent: "operator.manageParentalConsent",
  manageUnderageStatus: "operator.manageUnderageStatus",
  confirmAssignPhoneNumber: "operator.confirmAssignPhoneNumber",
  changeUserPhoneNumber: "operator.changeUserPhoneNumber",
  resendVerificationEmail: "operator.resendVerificationEmail",
  accessWalletData: "wallet.accessWalletsData",
  withdrawFromWallets: "wallet.withdrawFromWallets",
  topupWallets: "wallet.topupWallets",
  registerExternalTopUps: "wallet.registerExternalTopUps",
  manageCards: "operator.card.manageConfiguration",
  generateWarehouseMasterReport: "operator.warehouse.generateMasterReport",
  startRideForUser: "operator.startRideForUser",
  finishRide: "bikeRental.rides.finishRide",
  restartRide: "bikeRental.rides.restartRide",
  exportGpx: "operator.exportGpxFileReport",

  adjustFare: "operator.rides.adjustFare",
  resolveFareIssue: "operator.rides.resolveFareIssue",
  accessPaymentsSummaries: "operator.accessPaymentsSummaries",
  accessWalletCustomerData: "wallet.accessWalletCustomerData",
  bikes: {
    list: "bikeRental.bikes.listBikes",
    log: "bikeRental.bikes.bikeHistory",
    alerts: "operator.bikes.bikeAlerts",
    service: {
      access: "bikeRental.bikes.accessService",
      confirm: "bikeRental.bikes.confirmService",
    },
    types: "operator.bikes.manageBikeTypes",
    edit: "bikeRental.bikes.editBike",
    add: "bikeRental.bikes.registerBike",
    decommision: "bikeRental.bikes.decommisionBike",
    putOutOfService: "bikeRental.bikes.putOutOfService",
    putInService: "bikeRental.bikes.putInService",
    deactivateAlarm: "bikeRental.bikes.deactivateAlarm",
    requestTelemetry: "bikeRental.bikes.requestingTelemetry",
    addNote: "bikeRental.bikes.addBikeNote",
    sendLockComands: "bikeRental.bikes.sendLockComands",
    signalLockClosed: "bikeRental.bikes.signalLockClosed",
    disableBikeRentals: "operator.bikes.disableBikeRentals",
    accessDefectsData: "bikeRental.bikes.accessDefectsData",
  },
  reports: {
    access: "operator.accessFileReports",
    generate: "operator.generateFileReports",
    generateWithChosenData: "operator.generateReportsWithChosenData",
  },
};
