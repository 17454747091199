import React from "react";
import { Field, Form } from "react-final-form";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormSelect from "../../../../form/redux-form/FormSelect";
import FormTextField from "../../../../form/redux-form/FormTextField";

const NewPartForm = ({ onSubmit, onClose, categories, parts }) => {
  const filerParts = (categoryId) => {
    return parts
      .filter((x) => x.categoryId === categoryId)
      .map((x) => ({ value: x.id, name: x.name }));
  };

  const validation = (values) => {
    const errors = {};
    if (!values.partId) {
      errors.partId = "Pole wymagane";
    }
    if (values.newPartQuantity && values.newPartQuantity < 0) {
      errors.newPartQuantity = "Pole nie może być ujemne";
    }
    if (values.usedPartQuantity && values.usedPartQuantity < 0) {
      errors.usedPartQuantity = "Pole nie może być ujemne";
    }
    return errors;
  };
  return (
    <Form
      onSubmit={onSubmit}
      validate={validation}
      decorators={[
        (formApi) => {
          let before = formApi.getState();
          const unsubscribe = formApi.subscribe(
            (state) => {
              if (before.values.category !== state.values.category) {
                formApi.change("partId", null);
              }
              before = state;
            },
            { values: true },
          );
          return unsubscribe;
        },
      ]}
      render={({ handleSubmit, submitting, errors, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <DialogContent>
              <div>
                <div>
                  <Field
                    name="category"
                    id="category"
                    variant="outlined"
                    component={FormSelect}
                    label="Kategoria"
                    values={categories}
                    style={{ width: 300 }}
                  />
                </div>
                <div>
                  <Field
                    name="partId"
                    id="partId"
                    variant="outlined"
                    component={FormSelect}
                    label="Część"
                    readonly={!values.category}
                    values={values.category ? filerParts(values.category) : []}
                    style={{ width: 300 }}
                    error={errors.part && touched.part}
                    helperText={errors.part && touched.part && "Pole wymagane"}
                  />
                </div>
                <div>
                  <Field
                    name="newPartQuantity"
                    type="number"
                    style={{ width: 300 }}
                    component={FormTextField}
                    label="Liczba nowych części"
                    parse={(value) => parseInt(value, 10)}
                    format={(value) => (value == null ? "" : value)}
                  />
                </div>
                <div>
                  <Field
                    name="usedPartQuantity"
                    type="number"
                    style={{ width: 300 }}
                    component={FormTextField}
                    label="Liczba używanych części"
                    parse={(value) => parseInt(value, 10)}
                    format={(value) => (value == null ? "" : value)}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={submitting}>
                Anuluj
              </Button>
              <Button
                variant="contained"
                onClick={() => {}}
                disabled={submitting}
                color="primary"
                type="submit"
              >
                Zapisz
              </Button>
            </DialogActions>
          </div>
        </form>
      )}
    />
  );
};
NewPartForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  parts: PropTypes.array.isRequired,
};
export default NewPartForm;
