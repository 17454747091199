import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import FormTextField from "../../form/final-form/FormTextField";
import { IconButton, Typography } from "@material-ui/core";
import FormSelect from "../../form/redux-form/FormSelect";
import { FieldArray } from "react-final-form-arrays";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
const validation = (values) => {
  const errors = {};
  if (!values.resolutionComments)
    errors.resolutionComments = "Wprowadzenie notatki jest wymagane";
  if (!values.parts || values.parts.length === 0) {
    errors.part = "Wybranie co najmniej jednej części jest wymagane";
  }
  return errors;
};

const PartRow = ({ categories, part, onRemove }) => {
  const category = categories.find((x) => x.value === part.categoryId);

  return (
    <div
      style={{
        padding: "2px 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: 16 }}>
        {category ? `${category.name} - ` : ""} {part.name}
        {part.isUsed ? " (używany)" : " (nowy)"}
        {` - ${part.quantity}`}
      </div>
      <div>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onRemove}
          aria-label="Close"
        >
          <RemoveCircleIcon />
        </IconButton>
      </div>
    </div>
  );
};

PartRow.propTypes = {
  categories: PropTypes.array.isRequired,
  part: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const ResolveDefectWithPartsDialog = ({
  open,
  onSave,
  onCancel,
  categories,
  parts,
  submitting,
}) => {
  const filerParts = (categoryId) => {
    return parts.filter((x) => x.categoryId === categoryId);
  };

  const addPart = (currentParts, part, update, push) => {
    const currentPartIndex = currentParts.findIndex(
      (x) => x.id === part.id && x.isUsed === part.isUsed,
    );
    if (currentPartIndex >= 0) {
      const updatePart = currentParts[currentPartIndex];
      updatePart.quantity += 1;
      update("parts", currentPartIndex, updatePart);
    } else {
      push("parts", {
        categoryId: part.categoryId,
        id: part.id,
        isUsed: part.isUsed,
        name: part.name,
        quantity: 1,
      });
    }
  };

  const removePart = (currentParts, part, update, remove) => {
    const currentPartIndex = currentParts.findIndex(
      (x) => x.id === part.id && x.isUsed === part.isUsed,
    );
    if (currentPartIndex >= 0) {
      const updatePart = currentParts[currentPartIndex];
      if (updatePart.quantity <= 1) {
        remove("parts", currentPartIndex);
        return;
      }
      updatePart.quantity -= 1;
      update("parts", currentPartIndex, updatePart);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Zamykanie zgłoszenia z wymianą części</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSave}
          mutators={{ ...arrayMutators }}
          decorators={[
            (formApi) => {
              let before = formApi.getState();
              const unsubscribe = formApi.subscribe(
                (state) => {
                  if (before.values.category !== state.values.category) {
                    formApi.change("partId", null);
                  }
                  before = state;
                },
                { values: true },
              );
              return unsubscribe;
            },
          ]}
          validate={validation}
          initialValues={{ resolutionComments: "", parts: [] }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, remove, update },
            },
            values,
          }) => (
            <form onSubmit={handleSubmit} id="resolveDefectWithPartsForm">
              <div>
                <Field
                  name="category"
                  id="category"
                  variant="outlined"
                  component={FormSelect}
                  label="Kategoria"
                  values={categories}
                  fullWidth
                />
              </div>
              <div>
                <Field
                  name="part"
                  id="part"
                  variant="outlined"
                  readonly={!values.category}
                  component={FormSelect}
                  label="Część"
                  values={values.category ? filerParts(values.category) : []}
                  fullWidth
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    addPart(values.parts, values.part, update, push);
                  }}
                  disabled={submitting || !values.part}
                >
                  Dodaj część
                </Button>
              </div>
              <div>
                <Typography style={{ marginTop: 16 }}>
                  Lista wykorzystanych części
                </Typography>
                <div>
                  <FieldArray name="parts">
                    {({ fields }) =>
                      fields.map((name, index) => {
                        return (
                          <PartRow
                            key={name}
                            part={values.parts[index]}
                            categories={categories}
                            onRemove={() =>
                              removePart(
                                values.parts,
                                values.parts[index],
                                update,
                                remove,
                              )
                            }
                          />
                        );
                      })
                    }
                    {/*console.log("values.parts", values.parts)}
                    {values.parts &&
                      values.parts.map((x) => (
                        <PartRow part={x} categories={categories} />
                      ))*/}
                  </FieldArray>
                </div>
              </div>
              <div>
                <Field
                  name="resolutionComments"
                  component={FormTextField}
                  label="Notatka"
                  fullWidth
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          Anuluj
        </Button>
        <Button
          type="submit"
          form="resolveDefectWithPartsForm"
          disabled={submitting}
          variant="contained"
          color="primary"
        >
          Zamknij zgłoszenie
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/*const ResolveDefectWithPartsDialog = ({ open, onSave, onCancel, parts, submitting }) => {
  const selectableParts = parts
    .reduce((acc, part) => {
      acc[part.id] = { id: part.id, name: part.name, selected: false, count: 1 };
      return acc;
    }, {});
  return (
    <Dialog open={open}>
      <DialogTitle>Zamykanie zgłoszenia z wymianą części</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSave}
          mutators={{ ...arrayMutators }}
          validate={validation}
          initialValues={{ resolutionComments: '', parts: selectableParts }}
          render={({ handleSubmit, values, errors, touched }) => (
            <form onSubmit={handleSubmit} id="resolveDefectWithPartsForm">
              {
                Object.keys(values.parts).map(partId =>
                  <div key={partId}>
                    <Field
                      name={`parts.${partId}.selected`}
                      component={FormCheckBox}
                      type="checkbox"
                      label={values.parts[partId].name}
                      fullWidth
                    />
                    {
                      values.parts[partId].selected &&
                      <Field
                        name={`parts.${partId}.count`}
                        component={FormTextField}
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        label={"Liczba"}
                        fullWidth
                      />
                    }
                  </div>
                )
              }
              {errors && errors.parts && errors.parts._error && touched
                && <FormHelperText error>{errors.parts._error}</FormHelperText>}
              <div>
                <Field
                  name="resolutionComments"
                  component={FormTextField}
                  label="Notatka"
                  fullWidth
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting} >
          Anuluj
        </Button>
        <Button type="submit" form="resolveDefectWithPartsForm" disabled={submitting} variant='contained' color="primary">
          Zamknij zgłoszenie
        </Button>
      </DialogActions>
    </Dialog>
  );
}
*/
ResolveDefectWithPartsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  parts: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default ResolveDefectWithPartsDialog;
