import React from "react";
import PartListActionMenu from "./PartListActionMenu";
import TextFilter from "../../../common/reportTable/filters/TextFilter";
import SelectFilter from "../../../common/reportTable/filters/SelectFilter";

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */

export const getColumns = (categories) => {
  return [
    {
      Header: "",
      id: "show_details",
      Cell: (row) => <PartListActionMenu part={row.original} />,
      width: 50,
      sortable: false,
      Filter: () => null,
    },
    {
      Header: "Nazwa części",
      id: "name",
      accessor: (d) => (
        <div
          style={{
            padding: "0 10px",
            display: "flex",
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {d.name}
        </div>
      ),
      width: 200,
      Filter: ({ filter, onChange }) => (
        <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
      ),
    },
    {
      Header: "Kategoria",
      id: "categoryId",
      accessor: (d) => (
        <div
          style={{
            padding: "0 10px",
            display: "flex",
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {d.categoryName}
        </div>
      ),
      width: 200,
      Filter: ({ filter, onChange }) => {
        const result = {};
        categories.forEach((item) => {
          result[item.value] = {
            value: item.value,
            name: item.name,
          };
        });
        return (
          <SelectFilter
            onChange={onChange}
            filter={filter}
            items={result}
            label="filtruj..."
          />
        );
      },
    },
    {
      Header: "Liczba nowych części",
      id: "newPartQuantity",
      accessor: (d) => (
        <div
          style={{
            padding: "0 10px",
            display: "flex",
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {d.newPartQuantity}
        </div>
      ),
      width: 150,
      Filter: () => null,
    },
    {
      Header: "Liczba używanych części",
      id: "usedPartQuantity",
      accessor: (d) => (
        <div
          style={{
            padding: "0 10px",
            display: "flex",
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {d.usedPartQuantity}
        </div>
      ),
      width: 150,
      Filter: () => null,
    },
  ];
};
