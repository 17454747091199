import bikeDefectsApi from "../api/bikeDefectsApi";
import { actions as notificationActions } from "./notificationActions";
import * as logger from "../utils/logger";
import { push } from "connected-react-router";
import routePaths from "../routePaths";
import moment from "moment";

function getPosition(options) {
  if (!navigator.geolocation) return Promise.resolve(null);
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  );
}

async function getOperatorLocation() {
  try {
    const geolocationResult = await getPosition({});
    if (geolocationResult === null) return null;
    return {
      lng: geolocationResult.coords.longitude,
      lat: geolocationResult.coords.latitude,
    };
  } catch {
    console.info("could not get geolocation");
    return null;
  }
}

export const types = {
  LOAD_BIKE_DEFECTS: "LOAD_BIKE_DEFECTS",
  LOAD_FRESH_BIKE_DEFECTS_SUCCESS: "LOAD_FRESH_BIKE_DEFECTS_SUCCESS",
  LOAD_NEXT_BIKE_DEFECTS: "LOAD_NEXT_BIKE_DEFECTS",
  LOAD_NEXT_BIKE_DEFECTS_SUCCESS: "LOAD_NEXT_BIKE_DEFECTS_SUCCESS",
  LOAD_BIKE_DEFECT_DETAILS: "LOAD_BIKE_DEFECT_DETAILS",
  LOAD_BIKE_DEFECT_DETAILS_SUCCESS: "LOAD_BIKE_DEFECT_DETAILS_SUCCESS",
  RESOLVE_DEFECT: "RESOLVE_DEFECT",
  RESOLVE_DEFECT_SUCCESS: "RESOLVE_DEFECT_SUCCESS",
  RESOLVE_DEFECT_WITH_PARTS: "RESOLVE_DEFECT_WITH_PARTS",
  RESOLVE_DEFECT_WITH_PARTS_SUCCESS: "RESOLVE_DEFECT_WITH_PARTS_SUCCESS",
  REJECT_DEFECT: "REJECT_DEFECT",
  REJECT_DEFECT_SUCCESS: "REJECT_DEFECT_SUCCESS",
  GET_DEFECTS_CONFIGURATION: "GET_DEFECTS_CONFIGURATION",
  GET_DEFECTS_CONFIGURATION_SUCCESS: "GET_DEFECTS_CONFIGURATION_SUCCESS",
  GET_SERVICE_PARTS_LIST: "GET_SERVICE_PARTS_LIST",
  GET_SERVICE_PARTS_LIST_SUCCESS: "GET_SERVICE_PARTS_LIST_SUCCESS",
  UPDATE_DEFECTS_CONFIGURATION: "UPDATE_DEFECTS_CONFIGURATION",
  UPDATE_DEFECTS_CONFIGURATION_SUCCESS: "UPDATE_DEFECTS_CONFIGURATION_SUCCESS",
  GET_SERVICE_PARTS_REPORT: "GET_SERVICE_PARTS_REPORT",
  GET_SERVICE_PARTS_REPORT_SUCCESS: "GET_SERVICE_PARTS_REPORT_SUCCESS",
};

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === "string") {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const actions = {
  loadFreshBikeDefects:
    (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
      dispatch({
        type: types.LOAD_BIKE_DEFECTS,
        pageNumber,
        pageSize,
        sortBy,
        filterBy,
      });
      return bikeDefectsApi
        .getBikeDefects(pageNumber, pageSize, sortBy, filterBy)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: types.LOAD_FRESH_BIKE_DEFECTS_SUCCESS,
              result: res.data || {},
            });
          }
        });
    },

  loadNextBikeDefects: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_BIKE_DEFECTS, pageNumber });
    const sortBy = getState().bikeDefects.list.pagedData.sortedBy;
    const filterBy = getState().bikeDefects.list.pagedData.filteredBy;
    const pageSize = getState().bikeDefects.list.pagedData.paging.pageSize;
    return bikeDefectsApi
      .getBikeDefects(pageNumber, pageSize, sortBy, filterBy)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: types.LOAD_NEXT_BIKE_DEFECTS_SUCCESS,
            result: res.data || {},
          });
        }
      });
  },

  loadBikeDefectDetails: (defectId) => (dispatch) => {
    dispatch({ type: types.LOAD_BIKE_DEFECT_DETAILS, defectId });
    return bikeDefectsApi.getBikeDefectDetails(defectId).then((res) => {
      if (res.ok) {
        dispatch({
          type: types.LOAD_BIKE_DEFECT_DETAILS_SUCCESS,
          result: res.data || {},
        });
      }
    });
  },

  generateReport: (dateFrom, dateTo) => (dispatch) => {
    dispatch({ type: types.GET_SERVICE_PARTS_REPORT });
    return bikeDefectsApi
      .getReport(moment(dateFrom).toJSON(), moment(dateTo).toJSON())
      .then((response) => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.GET_SERVICE_PARTS_REPORT_SUCCESS });
          const disposition = response.headers["content-disposition"];
          const fileName = disposition
            ? disposition.match(/filename="(.+)"/)[1]
            : "eksport.xlsx";
          storeBlobAs(response.data, fileName);
          return true;
        } else {
          dispatch(
            notificationActions.showError(
              "Wystąpił problem podczas generowania raportu",
            ),
          );
          logger.error(response.data);
          return false;
        }
      });
  },

  resolveDefect: (defectId, data, currentUser) => async (dispatch) => {
    const operatorLocation = await getOperatorLocation();
    const dataWithLocation = Object.assign({}, data, { operatorLocation });
    dispatch({ type: types.RESOLVE_DEFECT, defectId });
    const response = await bikeDefectsApi.resolveDefect(
      defectId,
      dataWithLocation,
    );
    if (response.ok && response.data.success !== false) {
      dispatch({
        type: types.RESOLVE_DEFECT_SUCCESS,
        result: dataWithLocation,
        currentUser,
      });
      dispatch(notificationActions.showSuccess("Zgłoszenie zostało zamknięte"));
    } else {
      dispatch(
        notificationActions.showError(
          "Wystąpił problem podczas zamykania zgłoszenia",
        ),
      );
      return logger.error(response.data);
    }
  },

  resolveDefectWithParts: (defectId, data, currentUser) => async (dispatch) => {
    const operatorLocation = await getOperatorLocation();
    const dataWithLocation = Object.assign({}, data, { operatorLocation });
    dispatch({ type: types.RESOLVE_DEFECT_WITH_PARTS, defectId });
    const response = await bikeDefectsApi.resolveDefectWithParts(
      defectId,
      dataWithLocation,
    );
    if (response.ok && response.data.success !== false) {
      dispatch({
        type: types.RESOLVE_DEFECT_WITH_PARTS_SUCCESS,
        result: dataWithLocation,
        currentUser,
      });
      dispatch(notificationActions.showSuccess("Zgłoszenie zostało zamknięte"));
    } else {
      dispatch(
        notificationActions.showError(
          "Wystąpił problem podczas zamykania zgłoszenia",
        ),
      );
      return logger.error(response.data);
    }
  },
  resolveDefectWithPartsV2:
    (defectId, data, currentUser) => async (dispatch) => {
      const operatorLocation = await getOperatorLocation();
      const dataWithLocation = Object.assign({}, data, { operatorLocation });
      dispatch({ type: types.RESOLVE_DEFECT_WITH_PARTS, defectId });
      const response = await bikeDefectsApi.resolveDefectWithPartsV2(
        defectId,
        dataWithLocation,
      );
      if (response.ok && response.data.success !== false) {
        dispatch({
          type: types.RESOLVE_DEFECT_WITH_PARTS_SUCCESS,
          result: dataWithLocation,
          currentUser,
        });
        dispatch(
          notificationActions.showSuccess("Zgłoszenie zostało zamknięte"),
        );
        return { success: true };
      }
      if (response.ok && response.data.failure) {
        dispatch(notificationActions.showError(response.data.failure.message));
        return { success: false };
      }
      dispatch(
        notificationActions.showError(
          "Wystąpił problem podczas zamykania zgłoszenia",
        ),
      );
      return logger.error(response.data);
    },
  rejectDefect: (defectId, currentUser) => async (dispatch) => {
    const operatorLocation = await getOperatorLocation();
    const dataWithLocation = Object.assign({}, { operatorLocation });
    dispatch({ type: types.REJECT_DEFECT, defectId });
    const response = await bikeDefectsApi.rejectDefect(
      defectId,
      dataWithLocation,
    );
    if (response.ok && response.data.success !== false) {
      dispatch({
        type: types.REJECT_DEFECT_SUCCESS,
        result: dataWithLocation,
        currentUser,
      });
      dispatch(notificationActions.showSuccess("Zgłoszenie zostało odrzucone"));
    } else {
      dispatch(
        notificationActions.showError(
          "Wystąpił problem podczas odrzucania zgłoszenia",
        ),
      );
      return logger.error(response.data);
    }
  },

  getDefectsConfiguration: () => (dispatch) => {
    dispatch({ type: types.GET_DEFECTS_CONFIGURATION });
    return bikeDefectsApi.getDefectsConfiguration().then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({
          type: types.GET_DEFECTS_CONFIGURATION_SUCCESS,
          result: response.data,
        });
      } else {
        dispatch(
          notificationActions.showError(
            "Wystąpił problem podczas pobierania konfiguracji",
          ),
        );
        return logger.error(response.data);
      }
    });
  },

  getServicePartsList: () => (dispatch) => {
    dispatch({ type: types.GET_SERVICE_PARTS_LIST });
    return bikeDefectsApi.getServicePartsList().then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({
          type: types.GET_SERVICE_PARTS_LIST_SUCCESS,
          result: response.data,
        });
        return true;
      } else {
        dispatch(
          notificationActions.showError(
            "Wystąpił problem podczas pobierania listy części",
          ),
        );
        return logger.error(response.data);
      }
    });
  },

  updateDefectsConfiguration: (data) => (dispatch) => {
    dispatch({ type: types.UPDATE_DEFECTS_CONFIGURATION });
    return bikeDefectsApi.updateDefectsConfiguration(data).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.UPDATE_DEFECTS_CONFIGURATION_SUCCESS, data });
        dispatch(notificationActions.showSuccess("Zmiany zostały wprowadzone"));
        return dispatch(push(routePaths.bikeDefects));
      } else {
        dispatch(
          notificationActions.showError(
            "Wystąpił problem podczas aktualizacji konfiguracji",
          ),
        );
        return logger.error(response.data);
      }
    });
  },
};
