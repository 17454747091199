import { Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../actions/tenantActions";
import ServiceSettingsForm from "./ServiceSettingsForm";

const ServiceSettingsPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((s) => s.tenant);
  const {
    batteryLevelAlertThresholdPercentage,
    lockDelayAlertThresholdInMinutes,
    maximumTimeBetweenServiceDays,
    outOfZoneAlertThresholdInMinutes,
    messageForUser,
    longRidePushIntervalInHours,
    automaticBikeRestrictRentalsByDefectQuantity,
    minimumDayToHideBikeAfterLoseGPS,
  } = tenant;

  const handleSubmit = (data) => {
    dispatch(actions.updateServiceSettings(data));
  };

  return (
    <div className="page">
      <div style={{ margin: 40, paddingBottom: 20 }}>
        <Typography variant="h5" gutterBottom>
          Konfiguracja
        </Typography>
        <ServiceSettingsForm
          initialValues={{
            batteryLevelAlertThresholdPercentage,
            lockDelayAlertThresholdInMinutes,
            maximumTimeBetweenServiceDays,
            outOfZoneAlertThresholdInMinutes,
            longRidePushIntervalInHours,
            maximumTimeBetweenServiceEnabled:
              maximumTimeBetweenServiceDays !== null,
            lockDelayAlertEnabled: lockDelayAlertThresholdInMinutes !== null,
            outOfZoneAlertEnabled: outOfZoneAlertThresholdInMinutes !== null,
            batteryLevelAlertEnabled:
              batteryLevelAlertThresholdPercentage !== null,
            longRidePushEnabled: longRidePushIntervalInHours !== null,
            messageForUser,
            automaticBikeRestrictRentalsByDefectQuantity,
            automaticBikeRestrictRentalsByDefectQuantityEnabled:
              automaticBikeRestrictRentalsByDefectQuantity !== null,
            minimumDayToHideBikeAfterLoseGPS,
            minimumDayToHideBikeAfterLoseGPSEnabled:
              minimumDayToHideBikeAfterLoseGPS !== null,
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ServiceSettingsPage;
