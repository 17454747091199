import apiBase from "./apiBase";

class WarehouseApi {
  static getBikePartsList(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };

    return apiBase.create().post("/operations/warehouse/part/list", payload);
  }

  static getAllCategoriesAndParts() {
    return apiBase
      .create()
      .get("/operations/warehouse/tenant/categoriesAndParts");
  }

  static getAllCategoriesAndTenantParts() {
    return apiBase
      .create()
      .get("/operations/warehouse/tenant/categoriesAndTenantParts");
  }

  static addNewBikePartToTenant(payload) {
    return apiBase
      .create()
      .post("/operations/warehouse/tenant/part/new", payload);
  }

  static addBikePart(payload) {
    return apiBase
      .create()
      .post("/operations/warehouse/tenant/part/add", payload);
  }

  static moveBikePart(payload) {
    return apiBase
      .create()
      .post("/operations/warehouse/tenant/part/move", payload);
  }

  static removeBikePart(payload) {
    return apiBase
      .create()
      .post("/operations/warehouse/tenant/part/remove", payload);
  }

  static getReport(dateFrom, dateTo) {
    return apiBase
      .create()
      .get(
        "/operations/warehouse/report",
        { dateFrom, dateTo },
        { responseType: "blob", timeout: 120000 },
      );
  }

  static getMasterReport(dateFrom, dateTo) {
    return apiBase
      .create()
      .get(
        "/operations/warehouse/masterReport",
        { dateFrom, dateTo },
        { responseType: "blob", timeout: 120000 },
      );
  }
}

export default WarehouseApi;
