import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../actions/warehouseActions";
import { actions as tenantActions } from "../../../actions/tenantActions";
import PartList from "./partList/PartList";
import AddNewPartButton from "./partList/newPart/AddNewPartButton";
import GenerateReportDialog from "./GenerateReportDialog";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { hasPermission, permissions } from "../../../utils/authorization";
const WarehousePage = () => {
  const dispatch = useDispatch();

  const [reportData, setReportData] = useState({
    open: false,
    reportGenerating: false,
  });

  const tenantId = useSelector((state) => state.auth.tenantId);
  const operatorPermissions = useSelector((state) => state.auth.permissions);

  const allowTenants = ["magazyn", "dev"];

  useEffect(() => {
    dispatch(tenantActions.getAllTenants());
    dispatch(actions.getAllRowCategoryAndPart());
  }, [dispatch]);

  const handleGenerateReport = async (dateFrom, dateTo) => {
    setReportData({
      open: true,
      reportGenerating: true,
    });
    await dispatch(actions.generateReport(dateFrom, dateTo));
    setReportData({
      open: false,
      reportGenerating: false,
    });
  };

  const handleGenerateMasterReport = async () => {
    setReportData((x) => ({ ...x, reportGenerating: true }));
    await dispatch(actions.generateMasterReport());
    setReportData((x) => ({ ...x, reportGenerating: false }));
  };

  return (
    <div className="page">
      <div style={{ margin: 40, marginBottom: 0 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 15,
          }}
        >
          <AddNewPartButton />
          <Button
            variant="outlined"
            disabled={reportData.reportGenerating}
            style={{ marginLeft: 8 }}
            onClick={() => setReportData((x) => ({ ...x, open: true }))}
          >
            {reportData.reportGenerating && (
              <CircularProgress size={20} color="secondary" />
            )}
            {!reportData.reportGenerating && <DownloadIcon />}
            RAPORT
          </Button>
          {hasPermission(
            operatorPermissions,
            permissions.generateWarehouseMasterReport,
          ) &&
            allowTenants.includes(tenantId) && (
              <Button
                variant="outlined"
                disabled={reportData.reportGenerating}
                style={{ marginLeft: 8 }}
                onClick={handleGenerateMasterReport}
              >
                {reportData.reportGenerating && (
                  <CircularProgress size={20} color="secondary" />
                )}
                {!reportData.reportGenerating && <DownloadIcon />}
                RAPORT Zbiorczy
              </Button>
            )}
          <GenerateReportDialog
            open={reportData.open}
            submitting={reportData.reportGenerating}
            onSave={handleGenerateReport}
            onCancel={() => setReportData((x) => ({ ...x, open: false }))}
          />
        </div>
        <PartList />
      </div>
    </div>
  );
};

export default WarehousePage;
