import React, { useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import NewPartForm from "./NewPartForm";
import { actions } from "../../../../../actions/warehouseActions";
const AddNewPartButton = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const warehouseState = useSelector((state) => state.warehouse);

  const categoriesMemo = useMemo(() => {
    if (!warehouseState.categories) return [];
    return warehouseState.categories.map((x) => ({
      value: x.id,
      name: x.name,
    }));
  }, [warehouseState.categories]);

  const onSubmit = async (data) => {
    const result = await dispatch(
      actions.addNewBikePart(
        data.partId,
        data.newPartQuantity,
        data.usedPartQuantity,
      ),
    );
    if (result.success) {
      const pagedData = warehouseState.bikePartList;
      dispatch(
        actions.loadBikeParts(
          1,
          pagedData.paging.pageSize,
          pagedData.sortedBy,
          pagedData.filteredBy,
        ),
      );
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color={"primary"}
        onClick={() => {
          setOpen(true);
        }}
      >
        Dodaj nową część
      </Button>

      <Dialog open={open}>
        <DialogTitle>Dodaj nową część do magazynu</DialogTitle>
        <NewPartForm
          parts={warehouseState.parts}
          categories={categoriesMemo}
          onSubmit={onSubmit}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default AddNewPartButton;
