import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ManagePartForm from "./ManagePartForm";

const RemovePart = ({ open, onSubmit, onClose }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Usuń część</DialogTitle>
      <ManagePartForm onSubmit={onSubmit} onClose={onClose} />
    </Dialog>
  );
};

RemovePart.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RemovePart;
