import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment/moment";
import PropTypes from "prop-types";

const GenerateReportDialog = ({ open, onCancel, onSave, submitting }) => {
  const [date, setDate] = useState({
    reportFrom: moment().subtract(1, "month"),
    reportTo: moment(),
  });

  useEffect(() => {
    setDate({
      reportFrom: moment().subtract(1, "month"),
      reportTo: moment(),
    });
  }, [open]);

  return (
    <Dialog fullWidth open={open} onClose={onCancel}>
      <DialogTitle id="form-dialog-title">Generowanie raportu</DialogTitle>
      <DialogContent>
        <DialogContentText>Wybierz zakres reportu</DialogContentText>
        <DateTimePicker
          label={"Zakres od"}
          ampm={false}
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          autoOk
          okLabel="OK"
          cancelLabel="Anuluj"
          clearLabel="Wyczyść"
          invalidLabel="Wybierz ..."
          onChange={(value) => setDate((x) => ({ ...x, reportFrom: value }))}
          format="DD.MM.YYYY HH:mm:ss"
          value={date.reportFrom}
          clearable
        />
        <DateTimePicker
          label={"Zakres do"}
          ampm={false}
          margin="dense"
          minDate={date.reportFrom}
          InputLabelProps={{
            shrink: true,
          }}
          autoOk
          okLabel="OK"
          cancelLabel="Anuluj"
          clearLabel="Wyczyść"
          invalidLabel="Wybierz ..."
          onChange={(value) => setDate((x) => ({ ...x, reportTo: value }))}
          format="DD.MM.YYYY HH:mm:ss"
          value={date.reportTo}
          clearable
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Anuluj</Button>
        <Button
          disabled={submitting}
          onClick={() => onSave(date.reportFrom, date.reportTo)}
          color="primary"
          variant="contained"
        >
          Generuj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GenerateReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default GenerateReportDialog;
