import React, { useEffect, useMemo } from "react";
import Paper from "@material-ui/core/Paper";
import ReportTable from "../../../common/reportTable/ReportTable";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../actions/warehouseActions";
import { getColumns } from "./Columns";
import FiltersPanel from "./FiltersPanel";

const PartList = () => {
  const dispatch = useDispatch();

  const warehouseState = useSelector((state) => state.warehouse);

  const categoriesMemo = useMemo(() => {
    if (!warehouseState.categories) return [];
    return warehouseState.categories.map((x) => ({
      value: x.id,
      name: x.name,
    }));
  }, [warehouseState.categories]);

  useEffect(() => {
    const pagedData = warehouseState.bikePartList;
    dispatch(
      actions.loadBikeParts(
        1,
        pagedData.paging.pageSize,
        pagedData.sortedBy,
        pagedData.filteredBy,
      ),
    );
  }, []);
  const handleSortChange = (sortBy) => {
    const pagedData = warehouseState.bikePartList;
    dispatch(
      actions.loadBikeParts(
        1,
        pagedData.paging.pageSize,
        sortBy,
        pagedData.filteredBy,
      ),
    );
  };

  const handleFiltersChange = (filterBy) => {
    console.log("filterBy", filterBy);
    const pagedData = warehouseState.bikePartList;
    dispatch(
      actions.loadBikeParts(
        1,
        pagedData.paging.pageSize,
        pagedData.sortedBy,
        filterBy,
      ),
    );
  };

  const handlePageChange = (nextPage) => {
    const pagedData = warehouseState.bikePartList;
    dispatch(
      actions.loadBikeParts(
        nextPage,
        pagedData.paging.pageSize,
        pagedData.sortedBy,
        pagedData.filteredBy,
      ),
    );
  };

  const handlePageSizeChange = (nextPageSize) => {
    dispatch(
      actions.loadBikeParts(
        1,
        nextPageSize,
        warehouseState.bikePartList.sortedBy,
      ),
    );
  };

  return (
    <Paper style={{ padding: 16 }}>
      <FiltersPanel
        filteredBy={warehouseState.bikePartList.filteredBy}
        onFilteredChange={handleFiltersChange}
        categories={categoriesMemo}
      />
      <ReportTable
        style={{ height: "calc(100vh - 300px)" }}
        columns={getColumns(categoriesMemo)}
        data={warehouseState.bikePartList.records}
        filteredBy={warehouseState.bikePartList.filteredBy}
        sortedBy={warehouseState.bikePartList.sortedBy}
        paging={warehouseState.bikePartList.paging}
        onFilteredChange={handleFiltersChange}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortedChange={handleSortChange}
        loading={warehouseState.bikePartList.loading}
      />
    </Paper>
  );
};

export default PartList;
