import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { actions as cardActions } from "../../actions/cardsActions";
import { actions as plansActions } from "../../actions/plansActions";
import CardDetailsForm from "./CardDetailsForm";
import CardClients from "./CardClients";
import routePaths from "../../routePaths";
import DedicatedPlansDialog from "../reports/clientHistory/DedicatedPlansDialog";

const CardDetailsPage = ({ match }) => {
  const [tab, setTab] = useState("details");
  const [showDedicatePlan, setShowDedicatePlan] = useState(false);
  const [submittingSavePlans, setSubmittingSavePlans] = useState(false);
  const dispatch = useDispatch();

  const plans = useSelector((state) => state.plans);
  const cards = useSelector((state) => state.cards);

  const card = useMemo(() => {
    const cardsData = cards.data;
    return cardsData && cardsData.configured
      ? cardsData.configured.find((c) => c.id === match.params.id)
      : null;
  }, [cards, match]);

  useEffect(() => {
    dispatch(cardActions.load());
    dispatch(plansActions.getAllDedicatedPlans());
  }, [dispatch]);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const mappedPlans = useMemo(() => {
    if (!card) return [];
    let cardDedicatedPlans = [];
    if (card.dedicatedPlans !== null) {
      cardDedicatedPlans = card.dedicatedPlans;
    }

    return plans.map((plan) => {
      return {
        id: plan.id,
        name: plan.name,
        available: cardDedicatedPlans.includes(plan.id),
      };
    });
  }, [plans, card]);

  const savePlans = async (data) => {
    setSubmittingSavePlans(true);
    const planIds = Object.entries(data)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => value === true)
      .map(([key]) => key);
    const res = await dispatch(cardActions.savePlansToCard(card.id, planIds));
    setSubmittingSavePlans(false);
    if (res) {
      dispatch(cardActions.load());
      setShowDedicatePlan(false);
    }
  };

  if (cards.fetching || !card) {
    return (
      <div className="page">
        <div style={{ margin: 40 }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <CircularProgress size={50} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page">
      <div style={{ margin: 40 }}>
        <Typography variant="h5" gutterBottom>
          {card.cardProviderName}
        </Typography>
        <div style={{ textAlign: "right", marginBottom: 16 }}>
          <Button
            variant="contained"
            style={{ marginLeft: 8 }}
            component={Link}
            color="primary"
            to={routePaths.cardDetailsPricing.replace(":id", card && card.id)}
          >
            Cennik
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 8 }}
            color="primary"
            onClick={() => setShowDedicatePlan(true)}
          >
            Plan Dedykowany
          </Button>
        </div>
        <Paper>
          <Tabs
            value={tab}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleTabChange}
          >
            <Tab value="details" label="Szczegóły" />
            <Tab value="clients" label="Klienci" />
          </Tabs>
          <div style={{ padding: 16 }}>
            {tab === "details" && (
              <CardDetailsForm
                initialValues={{
                  ...card,
                  minimumUserBalanceEnabled: card.minimumUserBalance !== null,
                  initialUserBalanceEnabled: card.initialUserBalance !== null,
                }}
                onSubmit={(data) => dispatch(cardActions.update(data.id, data))}
              />
            )}
            {tab === "clients" && <CardClients cardId={card.id} />}
          </div>
        </Paper>
      </div>
      {showDedicatePlan && (
        <DedicatedPlansDialog
          title={"Wybierz plany dedykowane dla karty"}
          submitting={submittingSavePlans}
          open={showDedicatePlan}
          onCancel={() => setShowDedicatePlan(false)}
          onSave={savePlans}
          dedicatedPlans={mappedPlans}
        />
      )}
    </div>
  );
};

CardDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default CardDetailsPage;
