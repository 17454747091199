import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import TextFilter from "../../../common/reportTable/filters/TextFilter";
import SelectFilter from "../../../common/reportTable/filters/SelectFilter";

class FiltersPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getFilterValue(id) {
    return this.props.filteredBy.find((f) => f.id === id);
  }

  handleDelete(deleteFunction) {
    this.props.onFilteredChange(deleteFunction(this.props.filteredBy));
  }

  render() {
    const categories = {};
    this.props.categories.forEach((item) => {
      categories[item.value] = {
        value: item.value,
        name: item.name,
      };
    });
    return (
      <div className="filters-panel flex-row flex-wrap">
        <TextFilter
          filter={this.getFilterValue("name")}
          onDelete={this.handleDelete}
          readonly
          label="Nazwa"
        />
        <SelectFilter
          filter={this.getFilterValue("categoryId")}
          items={categories}
          onDelete={this.handleDelete}
          readonly
          label="Kategoria"
        />
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array,
  categories: PropTypes.array.isRequired,
};

export default FiltersPanel;
