import apiBase from "./apiBase";

class BikeDefectsApi {
  static getBikeDefects(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/operations/bikeDefects`, payload);
  }

  static getBikeDefectDetails(defectId) {
    return apiBase.create().get(`/operations/bikeDefects/${defectId}`);
  }

  static getReport(dateFrom, dateTo) {
    return apiBase
      .create()
      .get(
        "/operations/bikeDefects/report",
        { dateFrom, dateTo },
        { responseType: "blob", timeout: 120000 },
      );
  }

  static resolveDefect(defectId, data) {
    return apiBase
      .create()
      .post(`/operations/bikeDefects/${defectId}/resolve`, data);
  }

  static resolveDefectWithParts(defectId, data) {
    return apiBase
      .create()
      .post(`/operations/bikeDefects/${defectId}/resolve-with-parts`, data);
  }

  static resolveDefectWithPartsV2(defectId, data) {
    return apiBase
      .create()
      .post(
        `/operations/bikeDefects/${defectId}/resolve-with-parts?api-version=2`,
        data,
      );
  }

  static rejectDefect(defectId, data) {
    return apiBase
      .create()
      .post(`/operations/bikeDefects/${defectId}/reject`, data);
  }

  static getServicePartsList() {
    return apiBase.create().get("/operations/bikeDefects/service-bike-parts");
  }

  static getDefectsConfiguration() {
    return apiBase.create().get("/operations/bikeDefects/configuration");
  }

  static updateDefectsConfiguration(data) {
    return apiBase.create().put("/operations/bikeDefects/configuration", data);
  }
}

export default BikeDefectsApi;
