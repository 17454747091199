export default class TenantModel {
  constructor(data) {
    const values = data || {};
    this.id = values.id;
    this.areBikeRentalsRestricted = values.areBikeRentalsRestricted;
    this.pricings = values.pricings || {};
    this.operatorAutoLogoutTimeoutSeconds =
      values.operatorAutoLogoutTimeoutSeconds || null;
    this.maximumRentalsPerUser = values.maximumRentalsPerUser || null;
    this.maximumRentalsPerTester = values.maximumRentalsPerTester || 0;
    this.clientsActivityThresholdDays = values.clientsActivityThresholdDays;
    this.batteryLevelAlertThresholdPercentage =
      values.batteryLevelAlertThresholdPercentage;
    this.lockDelayAlertThresholdInMinutes =
      values.lockDelayAlertThresholdInMinutes;
    this.longRidePushIntervalInHours = values.longRidePushIntervalInHours;
    this.maximumTimeBetweenServiceDays = values.maximumTimeBetweenServiceDays;
    this.outOfZoneAlertThresholdInMinutes =
      values.outOfZoneAlertThresholdInMinutes;
    this.messageForUser = values.messageForUser;
    this.automaticBikeRestrictRentalsByDefectQuantity =
      values.automaticBikeRestrictRentalsByDefectQuantity;
    this.minimumDayToHideBikeAfterLoseGPS =
      values.minimumDayToHideBikeAfterLoseGPS;
    this.requirePeselNumber = values.requirePeselNumber;
    this.tenants = [];
  }
}
