import apiBase from "./apiBase";

class TenantApi {
  static getConfig() {
    return apiBase.create().get("/tenant");
  }
  static getAllTenants() {
    return apiBase.create().get("/operations/tenants");
  }
  static restrictRentals() {
    return apiBase.create().post("/tenant/restictBikeRentals");
  }

  static allowRentals() {
    return apiBase.create().post("/tenant/allowBikeRentals");
  }

  static updateAutoLogoutTimeout(timeout) {
    return apiBase.create().put("/tenant/operator-auto-logout-timeout", {
      autoLogoutTimeoutInSeconds: timeout,
    });
  }

  static updateServiceSettings(data) {
    const update = {
      batteryLevelAlertThresholdPercentage: data.batteryLevelAlertEnabled
        ? data.batteryLevelAlertThresholdPercentage
        : null,
      lockDelayAlertThresholdInMinutes: data.lockDelayAlertEnabled
        ? data.lockDelayAlertThresholdInMinutes
        : null,
      maximumTimeBetweenServiceDays: data.maximumTimeBetweenServiceEnabled
        ? data.maximumTimeBetweenServiceDays
        : null,
      outOfZoneAlertThresholdInMinutes: data.outOfZoneAlertEnabled
        ? data.outOfZoneAlertThresholdInMinutes
        : null,
      longRidePushIntervalInHours: data.longRidePushIntervalInHours
        ? data.longRidePushIntervalInHours
        : null,
      automaticBikeRestrictRentalsByDefectQuantity:
        data.automaticBikeRestrictRentalsByDefectQuantityEnabled
          ? data.automaticBikeRestrictRentalsByDefectQuantity
          : null,
      minimumDayToHideBikeAfterLoseGPS:
        data.minimumDayToHideBikeAfterLoseGPSEnabled
          ? data.minimumDayToHideBikeAfterLoseGPS
          : null,
      messageForUser: data.messageForUser,
    };
    return apiBase.create().put("/tenant/service-settings", update);
  }

  static updateClientsActivityThresholdDays(clientsActivityThresholdDays) {
    return apiBase.create().put("/tenant/clients-activity-threshold-days", {
      clientsActivityThresholdDays,
    });
  }

  static updateMaximumRentalsPerUser(
    maximumRentalsPerUser,
    maximumRentalsPerTester,
  ) {
    return apiBase.create().put("/tenant/maximum-rentals-per-user", {
      maximumRentalsPerUser,
      maximumRentalsPerTester,
    });
  }
}

export default TenantApi;
