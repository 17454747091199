import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import DedicatedPlansForm from "./DedicatedPlansForm";

const DedicatedPlansDialog = ({
  open,
  onSave,
  title,
  onCancel,
  dedicatedPlans,
}) => (
  <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DedicatedPlansForm
        onSubmit={onSave}
        onCancel={onCancel}
        dedicatedPlans={dedicatedPlans}
      />
    </DialogContent>
  </Dialog>
);

DedicatedPlansDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  dedicatedPlans: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default connect()(DedicatedPlansDialog);
