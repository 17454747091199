import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import MovePartForm from "./MovePartForm";

const MovePart = ({ onSubmit, onClose, open }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Przenieś część do innego magazynu</DialogTitle>
      <MovePartForm onSubmit={onSubmit} onClose={onClose} />
    </Dialog>
  );
};

MovePart.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MovePart;
