export default {
  home: "/",
  signIn: "/signin",
  twoFactorAuth: "/signin/two-factor-auth",
  accountActivation: "/account-activation",
  passwordRecovery: "/password-recovery",
  passwordReset: "/password-reset",
  passwordRequireChange: "/password-require-change",
  pricings: "/pricings",
  createPricing: "/pricings/create",
  pricingDetails: "/pricing/",
  pricingPublic: "/pricing-public",
  plans: "/plans",
  planDetails: "/plans/",
  createPlan: "/plans/create",
  account: {
    me: "/account/me",
    twoFA: "/account/two-factor-auth",
  },
  service: {
    bikeListFiltered: "/service/bikes?filter=",
    bikeList: "/service/bikes",
    map: "/service/map",
    settings: "/service/settings",
    warehouse: "/service/warehouse",
  },
  bikes: "/bikes",
  bikesFiltered: "/bikes?filter=",
  createBike: "/bikes/create",
  editBike: "/bikes/edit",
  bikeTypes: "/bikeTypes",
  createBikeType: "/bikeTypes/create",
  bikeDetails: "/bikes/bikeDetails",
  operators: "/operators",
  createOperator: "/operators/create",
  autoLogoutSettings: "/operators/auto-logout-settings",
  activityThesholdSettings: "/operators/clients-activity-threshold-settings",
  maximumRentalsPerUser: "/operators/maximum-rentals-per-user",
  payout: "/payout",
  zones: "/zones",
  ridesReports: "/reports/rides",
  ridesDetailsReportsPage: "/reports/ridesDetailsReportsPage",
  notStartedRidesDetailsReportsPage:
    "/reports/notStaredRidesDetailsReportsPage",
  termsAndConditions: "/terms",
  termsAndConditionsDetails: "/terms/",
  termsAndConditionsEdit: "/terms/edit",
  termsAndConditionsEditClause: "/terms/edit-clause",
  clientsReport: "/reports/clients",
  globalClientsReport: "/reports/clients/global",
  clientsDenyListReport: "/reports/clients/deny-list",
  campaigns: "/campaigns",
  campaignDetails: "/campaigns/",
  bikeDefects: "/bike-defects",
  bikeDefectsFiltered: "/bike-defects?bikeNumber=",
  bikeDefectDetails: "/bike-defects/",
  bikeDefectsConfiguration: "/bike-defects/configuration",
  zoneTypes: "/zoneTypes",
  createZoneType: "/zoneTypes/create",
  relocationReport: "/reports/relocation",
  relocationConfiguration: "/reports/relocation/configuration",
  reports: "/reports/list",
  reportsCustom: "/reports/custom/list",
  reportCustomDetails: "/reports/custom/details",
  reportsCustomCreate: "/reports/custom/create",
  cards: "/cards",
  cardDetails: "/cards/:id",
  cardDetailsPricing: "/cards/pricings/:id",
};
