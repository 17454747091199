import { actions as notificationActions } from "./notificationActions";
import * as logger from "../utils/logger";
import warehouseApi from "../api/warehouseApi";
import moment from "moment/moment";

export const types = {
  LOAD_BIKE_PARTS_SUBMIT: "LOAD_BIKE_PARTS_SUBMIT",
  LOAD_BIKE_PARTS_SUCCESS: "LOAD_BIKE_PARTS_SUCCESS",
  LOAD_BIKE_PARTS_FAILURE: "LOAD_BIKE_PARTS_FAILURE",
  LOAD_NEXT_BIKE_PARTS_SUBMIT: "LOAD_NEXT_BIKE_PARTS_SUBMIT",
  LOAD_NEXT_BIKE_PARTS_SUCCESS: "LOAD_NEXT_BIKE_PARTS_SUCCESS",
  LOAD_NEXT_BIKE_PARTS_FAILURE: "LOAD_NEXT_BIKE_PARTS_FAILURE",
  LOAD_ALL_CATEGORIES_AND_PARTS_SUBMIT: "LOAD_ALL_CATEGORIES_AND_PARTS_SUBMIT",
  LOAD_ALL_CATEGORIES_AND_PARTS_SUCCESS:
    "LOAD_ALL_CATEGORIES_AND_PARTS_SUCCESS",
  LOAD_ALL_CATEGORIES_AND_PARTS_FAILURE:
    "LOAD_ALL_CATEGORIES_AND_PARTS_FAILURE",
  LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_SUBMIT:
    "LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_SUBMIT",
  LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_SUCCESS:
    "LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_SUCCESS",
  LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_FAILURE:
    "LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_FAILURE",
};

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === "string") {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const actions = {
  getAllRowCategoryAndPart: () => (dispatch) => {
    dispatch({
      type: types.LOAD_ALL_CATEGORIES_AND_PARTS_SUBMIT,
    });
    return warehouseApi.getAllCategoriesAndParts().then((response) => {
      if (response.ok) {
        return dispatch({
          type: types.LOAD_ALL_CATEGORIES_AND_PARTS_SUCCESS,
          result: response.data || {},
        });
      } else {
        dispatch({ type: types.LOAD_ALL_CATEGORIES_AND_PARTS_FAILURE });
        return logger.error(response.data);
      }
    });
  },
  getAllCategoriesAndTenantParts: () => (dispatch) => {
    dispatch({
      type: types.LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_SUBMIT,
    });
    return warehouseApi.getAllCategoriesAndTenantParts().then((response) => {
      if (response.ok) {
        return dispatch({
          type: types.LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_SUCCESS,
          result: response.data || {},
        });
      } else {
        dispatch({ type: types.LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_FAILURE });
        return logger.error(response.data);
      }
    });
  },
  loadBikeParts: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({
      type: types.LOAD_BIKE_PARTS_SUBMIT,
      pageNumber,
      pageSize,
      sortBy,
      filterBy,
    });
    return warehouseApi
      .getBikePartsList(pageNumber, pageSize, sortBy, filterBy)
      .then((response) => {
        if (response.ok) {
          return dispatch({
            type: types.LOAD_BIKE_PARTS_SUCCESS,
            result: response.data || {},
          });
        } else {
          dispatch({ type: types.LOAD_BIKE_PARTS_FAILURE });
          dispatch(
            notificationActions.showError(
              "Nie udało się załadować części rowerów",
            ),
          );

          return logger.error(response.data);
        }
      });
  },
  addNewBikePart: (partId, newPartQuantity, usedPartQuantity) => (dispatch) => {
    return warehouseApi
      .addNewBikePartToTenant({ partId, newPartQuantity, usedPartQuantity })
      .then((response) => {
        if (response.ok) {
          if (response.data.failure) {
            dispatch(
              notificationActions.showError(response.data.failure.message),
            );
            return { success: false };
          }
          return { success: true };
        } else {
          dispatch(
            notificationActions.showError("Nie udało się dodać nowej części"),
          );
          return logger.error(response.data);
        }
      });
  },
  moveBikePart:
    (partId, newPartQuantity, usedPartQuantity, targetWarehouseId) =>
    (dispatch) => {
      return warehouseApi
        .moveBikePart({
          partId,
          newPartQuantity,
          usedPartQuantity,
          targetWarehouseId,
        })
        .then((response) => {
          if (response.ok) {
            if (response.data.failure) {
              dispatch(
                notificationActions.showError(response.data.failure.message),
              );
              return { success: false };
            }
            return { success: true };
          } else {
            dispatch(
              notificationActions.showError("Nie udało się przenieś części"),
            );
            return logger.error(response.data);
          }
        });
    },
  addBikePart: (partId, newPartQuantity, usedPartQuantity) => (dispatch) => {
    return warehouseApi
      .addBikePart({ partId, newPartQuantity, usedPartQuantity })
      .then((response) => {
        if (response.ok) {
          if (response.data.failure) {
            dispatch(
              notificationActions.showError("Nie udało się dodać części"),
            );
            return { success: false };
          }
          return { success: true };
        } else {
          dispatch(notificationActions.showError("Nie udało się dodać części"));
          return logger.error(response.data);
        }
      });
  },
  removeBikePart: (partId, newPartQuantity, usedPartQuantity) => (dispatch) => {
    return warehouseApi
      .removeBikePart({ partId, newPartQuantity, usedPartQuantity })
      .then((response) => {
        if (response.ok) {
          if (response.data.failure) {
            dispatch(
              notificationActions.showError("Nie udało się usunać części"),
            );
            return { success: false };
          }
          return { success: true };
        } else {
          dispatch(
            notificationActions.showError("Nie udało się usunąć części"),
          );
          return logger.error(response.data);
        }
      });
  },
  generateReport: (dateFrom, dateTo) => (dispatch) => {
    return warehouseApi
      .getReport(moment(dateFrom).toJSON(), moment(dateTo).toJSON())
      .then((response) => {
        if (response.ok && response.data.success !== false) {
          const disposition = response.headers["content-disposition"];
          const fileName = disposition
            ? disposition.match(/filename="(.+)"/)[1]
            : "magazyn_audyt.xlsx";
          storeBlobAs(response.data, fileName);
          return true;
        } else {
          dispatch(
            notificationActions.showError(
              "Wystąpił problem podczas generowania raportu",
            ),
          );
          logger.error(response.data);
          return false;
        }
      });
  },
  generateMasterReport: () => (dispatch) => {
    return warehouseApi.getMasterReport().then((response) => {
      if (response.ok && response.data.success !== false) {
        const disposition = response.headers["content-disposition"];
        const fileName = disposition
          ? disposition.match(/filename="(.+)"/)[1]
          : "magazyn_master_audyt.xlsx";
        storeBlobAs(response.data, fileName);
        return true;
      } else {
        dispatch(
          notificationActions.showError(
            "Wystąpił problem podczas generowania raportu",
          ),
        );
        logger.error(response.data);
        return false;
      }
    });
  },
};
