import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import DialogContent from "@material-ui/core/DialogContent";
import FormTextField from "../../../../form/redux-form/FormTextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import FormSelect from "../../../../form/redux-form/FormSelect";
const MovePartForm = ({ onSubmit, onClose }) => {
  const tenantState = useSelector((x) => x.tenant);

  const tenantsList = useMemo(() => {
    if (!tenantState.tenants) return [];
    return tenantState.tenants.map((x) => ({
      value: x.tenantId,
      name: x.tenantId,
    }));
  }, [tenantState.tenants]);

  const validation = (values) => {
    const errors = {};

    if (!values.targetWarehouseId) {
      errors.targetWarehouseId = "Pole wymagane";
    }
    if (values.newPartQuantity && values.newPartQuantity < 0) {
      errors.newPartQuantity = "Pole nie może mieć wartości ujemnej";
    }
    if (values.usedPartQuantity && values.usedPartQuantity < 0) {
      errors.newPartQuantity = "Pole nie może mieć wartości ujemnej";
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validation}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <DialogContent>
              <div>
                <div>
                  <Field
                    name="targetWarehouseId"
                    component={FormSelect}
                    values={tenantsList}
                    label="Magazyn docelowy"
                    fullWidth
                  />
                </div>
                <div>
                  <Field
                    name="newPartQuantity"
                    type="number"
                    style={{ width: 300 }}
                    component={FormTextField}
                    label="Liczba nowych części"
                    parse={(value) => parseInt(value, 10)}
                    format={(value) => (value == null ? "" : value)}
                  />
                </div>
                <div>
                  <Field
                    name="usedPartQuantity"
                    type="number"
                    style={{ width: 300 }}
                    component={FormTextField}
                    label="Liczba używanych części"
                    parse={(value) => parseInt(value, 10)}
                    format={(value) => (value == null ? "" : value)}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={submitting}>
                Anuluj
              </Button>
              <Button
                variant="contained"
                onClick={() => {}}
                disabled={submitting}
                color="primary"
                type="submit"
              >
                Zapisz
              </Button>
            </DialogActions>
          </div>
        </form>
      )}
    />
  );
};

MovePartForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MovePartForm;
