import { BikeModel } from "../models/BikeModel";
import { AuthModel } from "../models/auth/AuthModel";
import { PricingModel } from "../models/configuration/PricingModel";
import { BikeDetailsModel } from "../models/bikesManagement/BikeDetailsModel";
import { VendorBalanceModel } from "../models/payments/VendorBalanceModel";
import { OperatorEditModel } from "../models/operators/OperatorEditModel";
import { RideReportsModel } from "./reports/rides/models";
import { RideDetailsReportModel } from "./reports/rideDetails/models";
import { TermsAndConditionsModel } from "../models/termsAndConditions/TermsAndConditionsModel";
import { ClientsReportModel } from "./reports/clients/models";
import { ClientHistoryReportModel } from "./reports/clientHistory/models";
import { ClientWalletModel } from "./reports/clientWallet/models";
import { BikeDefectsModel } from "../models/bikeDefects/BikeDefectsModel";
import TenantModel from "../models/TenantModel";
import { RelocationReportModel } from "../models/relocation/RelocationReportModel";
import { ReportTableModel } from "../models/ReportTableModel";
import { CampaignsModel } from "../models/campaigns/CampaignsModel";

export default {
  auth: new AuthModel(null),
  bikes: [],
  bikeTypes: { list: [], current: {} },
  bikeDetails: new BikeModel(),
  bikeAlerts: {
    fetching: false,
    data: [],
  },
  bikeServiceHistory: {
    fetching: false,
    data: [],
  },
  bikeHistory: {
    fetching: false,
    data: [],
  },
  bikeManagementDetails: new BikeDetailsModel(),
  bikeFilters: [],
  loaders: {
    isScreenLoading: false,
    isDialogLoading: false,
  },
  errors: [],
  tenant: new TenantModel(),
  twoFA: {
    topt: {
      secretKey: null,
      code: null,
      otpUrl: null,
      account: null,
      success: false,
      loading: false,
    },
  },
  configuration: {
    initialLocation: { lat: 52.40518888291262, lng: 16.86149340122938 },
    initialZoom: 13,
  },
  pricing: {
    list: [],
    details: new PricingModel(),
  },
  plans: [],
  notifications: [],
  vendorBalance: new VendorBalanceModel(),
  zones: [],
  zoneTypes: { list: [], current: {} },
  operators: { list: [], current: new OperatorEditModel() },
  warehouse: {
    tenantParts: [],
    tenantCategories: [],
    parts: [],
    categories: [],
    bikePartList: new ReportTableModel(
      { pageNumber: 1, pageSize: 100 },
      [{ id: "name", value: "desc" }],
      [],
    ),
  },
  reports: {
    rides: new RideReportsModel(),
    rideDetails: new RideDetailsReportModel(),
    clients: new ClientsReportModel(),
    clientsDenyList: new ClientsReportModel(),
    clientHistory: new ClientHistoryReportModel(),
    clientWallet: new ClientWalletModel(),
    clientCards: {
      loading: false,
      pagedData: new ReportTableModel(
        { pageNumber: 1, pageSize: 100 },
        [{ id: "addedAt", value: "desc" }],
        [],
      ),
    },
    relocation: new RelocationReportModel(),
    cardClients: {
      loading: false,
      pagedData: new ReportTableModel(
        { pageNumber: 1, pageSize: 100 },
        [{ id: "addedAt", value: "desc" }],
        [],
      ),
    },
    list: {
      savingTemplateCustomReport: false,
      loading: false,
      pagedData: new ReportTableModel(
        { pageNumber: 1, pageSize: 100 },
        [{ id: "queuedAt", value: "desc" }],
        [],
      ),
      loadingCustomReportList: false,
      customReportList: [],
      loadingCustomReport: false,
      customReport: null,
    },
  },
  relocationConfiguration: {
    loading: false,
    data: [],
  },
  termsAndConditions: new TermsAndConditionsModel(),
  bikeDefects: {
    list: new BikeDefectsModel(),
    serviceParts: [],
    details: {},
  },
  campaigns: {
    list: new CampaignsModel(),
    details: {
      fetching: false,
      data: null,
    },
  },
  cards: {
    fetching: true,
    data: null,
  },
  globalBikeTypes: {
    fetching: true,
    data: [],
  },
  bikeDefectsConfiguration: {
    fetching: true,
    data: null,
  },
};
