import Immutable from "seamless-immutable";
import initialState from "./initialState";
import { types } from "../actions/warehouseActions";
import { ReportTableModel } from "../models/ReportTableModel";

export default function warehouseReducer(
  state = Immutable(initialState.warehouse),
  action,
) {
  switch (action.type) {
    case types.LOAD_BIKE_PARTS_SUBMIT: {
      return Immutable.merge(state, {
        bikePartList: ReportTableModel.pageRequested(
          state.bikePartList,
          action.pageNumber,
          action.pageSize,
          action.filterBy,
          action.sortBy,
        ),
      });
    }
    case types.LOAD_BIKE_PARTS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        bikePartList: ReportTableModel.pageReady(
          state.bikePartList,
          result.totalDataCount,
          result.data,
        ),
      });
    }
    case types.LOAD_NEXT_BIKE_PARTS_SUBMIT: {
      return Immutable.merge(state, {
        bikePartList: ReportTableModel.nextPageRequested(
          state.bikePartList,
          action.pageNumber,
          action.pageSize,
        ),
      });
    }
    case types.LOAD_ALL_CATEGORIES_AND_PARTS_SUCCESS: {
      return Immutable.merge(state, {
        parts: action.result.parts,
        categories: action.result.categories,
      });
    }
    case types.LOAD_ALL_CATEGORIES_AND_TENANTS_PARTS_SUCCESS: {
      return Immutable.merge(state, {
        tenantParts: action.result.parts,
        tenantCategories: action.result.categories,
      });
    }

    case types.LOAD_NEXT_BIKE_PARTS_SUCCESS: {
      const result = action.result;
      const paging = {
        pageNumber: result.pageNumber,
        pageSize: result.pageSize,
        totalPages: Math.ceil(result.totalDataCount / result.pageSize) || 1,
        totalSize: result.totalDataCount,
      };
      return Immutable.merge(state, {
        bikePartList: ReportTableModel.pageReady(
          state.bikePartList,
          result.totalDataCount,
          result.data,
          paging,
        ),
      });
    }

    default:
      return state;
  }
}
