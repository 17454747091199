import tenantApi from "../api/tenantApi";
import { actions as notificationActions } from "./notificationActions";
import * as logger from "../utils/logger";

export const types = {
  GET_TENANT_CONFIG: "GET_TENANT_CONFIG",
  GET_TENANT_CONFIG_SUCCESS: "GET_TENANT_CONFIG_SUCCESS",
  GET_TENANT_CONFIG_FAILURE: "GET_TENANT_CONFIG_FAILURE",
  RESTRICT_RENTALS_SUBMIT: "RESTRICT_RENTALS_SUBMIT",
  RESTRICT_RENTALS_SUCCESS: "RESTRICT_RENTALS_SUCCESS",
  RESTRICT_RENTALS_FAILURE: "RESTRICT_RENTALS_FAILURE",
  ALLOW_RENTALS_SUBMIT: "ALLOW_RENTALS_SUBMIT",
  ALLOW_RENTALS_SUCCESS: "ALLOW_RENTALS_SUCCESS",
  ALLOW_RENTALS_FAILURE: "ALLOW_RENTALS_FAILURE",
  UPDATE_ACTIVITY_THRESHOLD: "UPDATE_ACTIVITY_THRESHOLD",
  UPDATE_ACTIVITY_THRESHOLD_FAILURE: "UPDATE_ACTIVITY_THRESHOLD_FAILURE",
  UPDATE_ACTIVITY_THRESHOLD_SUCCESS: "UPDATE_ACTIVITY_THRESHOLD_SUCCESS",
  UPDATE_AUTO_LOGOUT_REQUEST: "UPDATE_AUTO_LOGOUT_REQUEST",
  UPDATE_AUTO_LOGOUT_SUCCESS: "UPDATE_AUTO_LOGOUT_SUCCESS",
  UPDATE_AUTO_LOGOUT_FAILURE: "UPDATE_AUTO_LOGOUT_FAILURE",
  UPDATE_SERVICE_SETTINGS_REQUEST: "UPDATE_SERVICE_SETTINGS_REQUEST",
  UPDATE_SERVICE_SETTINGS_SUCCESS: "UPDATE_SERVICE_SETTINGS_SUCCESS",
  UPDATE_SERVICE_SETTINGS_FAILURE: "UPDATE_SERVICE_SETTINGS_FAILURE",
  UPDATE_MAXIMUM_RENTALS_PER_USER_REQUEST:
    "UPDATE_MAXIMUM_RENTALS_PER_USER_REQUEST",
  UPDATE_MAXIMUM_RENTALS_PER_USER_SUCCESS:
    "UPDATE_MAXIMUM_RENTALS_PER_USER_SUCCESS",
  UPDATE_MAXIMUM_RENTALS_PER_USER_FAILURE:
    "UPDATE_MAXIMUM_RENTALS_PER_USER_FAILURE",
  GET_ALL_TENANTS: "GET_ALL_TENANTS",
  GET_ALL_TENANTS_SUCCESS: "GET_ALL_TENANTS_SUCCESS",
  GET_ALL_TENANTS_FAILURE: "GET_ALL_TENANTS_FAILURE",
};

export const actions = {
  loadTenantConfig: () => (dispatch) => {
    dispatch({ type: types.GET_TENANT_CONFIG });
    return tenantApi.getConfig().then((response) => {
      if (response.ok) {
        return dispatch({
          type: types.GET_TENANT_CONFIG_SUCCESS,
          tenantConfig: response.data,
        });
      } else {
        dispatch({ type: types.GET_TENANT_CONFIG_FAILURE });
        dispatch(
          notificationActions.showError("Nie udało się załadować konfiguracji"),
        );

        return logger.error(response.data);
      }
    });
  },
  getAllTenants: () => (dispatch) => {
    dispatch({ type: types.GET_ALL_TENANTS });
    return tenantApi.getAllTenants().then((response) => {
      if (response.ok) {
        return dispatch({
          type: types.GET_ALL_TENANTS_SUCCESS,
          tenants: response.data,
        });
      } else {
        dispatch({ type: types.GET_ALL_TENANTS_FAILURE });
        dispatch(
          notificationActions.showError(
            "Nie udało się załadować listy tenantów",
          ),
        );

        return logger.error(response.data);
      }
    });
  },
  restrictBikeRentals: () => (dispatch) => {
    dispatch({ type: types.RESTRICT_RENTALS_SUBMIT });
    return tenantApi.restrictRentals().then((response) => {
      if (response.ok) {
        return dispatch({ type: types.RESTRICT_RENTALS_SUCCESS });
      } else {
        dispatch({ type: types.RESTRICT_RENTALS_FAILURE });
        dispatch(
          notificationActions.showError(
            "Nie udało się wyłączyć możliwości wypożyczania rowerów",
          ),
        );
        return logger.error(response.data);
      }
    });
  },
  allowBikeRentals: () => (dispatch) => {
    dispatch({ type: types.ALLOW_RENTALS_SUBMIT });
    return tenantApi.allowRentals().then((response) => {
      if (response.ok) {
        return dispatch({ type: types.ALLOW_RENTALS_SUCCESS });
      } else {
        dispatch({ type: types.ALLOW_RENTALS_FAILURE });
        dispatch(
          notificationActions.showError(
            "Nie udało się włączyć możliwości wypożyczania rowerów",
          ),
        );
        return logger.error(response.data);
      }
    });
  },
  updateAutoLogoutSettings: (timeout) => (dispatch) => {
    dispatch({ type: types.UPDATE_AUTO_LOGOUT_REQUEST, timeout });
    return tenantApi.updateAutoLogoutTimeout(timeout).then((response) => {
      if (response.ok) {
        dispatch({ type: types.UPDATE_AUTO_LOGOUT_SUCCESS, timeout });
        dispatch(notificationActions.showSuccess("Zapisano ustawienia"));
        return true;
      } else {
        dispatch({ type: types.UPDATE_AUTO_LOGOUT_FAILURE });
        dispatch(
          notificationActions.showError("Nie udało się zapisać ustawień"),
        );
        logger.error(response.data);
        return false;
      }
    });
  },
  updateServiceSettings: (data) => (dispatch) => {
    dispatch({ type: types.UPDATE_SERVICE_SETTINGS_REQUEST, ...data });
    return tenantApi.updateServiceSettings(data).then((response) => {
      if (response.ok) {
        dispatch({ type: types.UPDATE_SERVICE_SETTINGS_SUCCESS, ...data });
        dispatch(notificationActions.showSuccess("Zapisano ustawienia"));
        return true;
      } else {
        dispatch({ type: types.UPDATE_SERVICE_SETTINGS_FAILURE });
        dispatch(
          notificationActions.showError("Nie udało się zapisać ustawień"),
        );
        logger.error(response.data);
        return false;
      }
    });
  },
  updateClientsActivityThresholdDays:
    (clientsActivityThresholdDays) => (dispatch) => {
      dispatch({
        type: types.UPDATE_ACTIVITY_THRESHOLD,
        clientsActivityThresholdDays,
      });
      return tenantApi
        .updateClientsActivityThresholdDays(clientsActivityThresholdDays)
        .then((response) => {
          if (response.ok) {
            dispatch({
              type: types.UPDATE_ACTIVITY_THRESHOLD_SUCCESS,
              clientsActivityThresholdDays,
            });
            dispatch(notificationActions.showSuccess("Zapisano ustawienia"));
            return true;
          } else {
            dispatch({ type: types.UPDATE_ACTIVITY_THRESHOLD_FAILURE });
            dispatch(
              notificationActions.showError("Nie udało się zapisać ustawień"),
            );
            logger.error(response.data);
            return false;
          }
        });
    },
  updateMaximumRentalsPerUser:
    (maximumRentalsPerUser, maximumRentalsPerTester) => (dispatch) => {
      dispatch({
        type: types.UPDATE_MAXIMUM_RENTALS_PER_USER_REQUEST,
        maximumRentalsPerUser,
        maximumRentalsPerTester,
      });
      return tenantApi
        .updateMaximumRentalsPerUser(
          maximumRentalsPerUser,
          maximumRentalsPerTester,
        )
        .then((response) => {
          if (response.ok) {
            dispatch({
              type: types.UPDATE_MAXIMUM_RENTALS_PER_USER_SUCCESS,
              maximumRentalsPerUser,
              maximumRentalsPerTester,
            });
            dispatch(notificationActions.showSuccess("Zapisano ustawienia"));
            return true;
          } else {
            dispatch({ type: types.UPDATE_MAXIMUM_RENTALS_PER_USER_FAILURE });
            dispatch(
              notificationActions.showError("Nie udało się zapisać ustawień"),
            );
            logger.error(response.data);
            return false;
          }
        });
    },
};
