import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import DashboardIcon from "@material-ui/icons/Home";
import PricingIcon from "@material-ui/icons/MonetizationOn";
import PlansIcon from "@material-ui/icons/LocalAtm";
import TermsIcon from "@material-ui/icons/ListAlt";
import AlertIcon from "@material-ui/icons/ErrorOutline";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import Defect from "@material-ui/icons/Build";
import Map from "@material-ui/icons/Map";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Timeline from "@material-ui/icons/Timeline";
import People from "@material-ui/icons/People";
import CardsIcon from "@material-ui/icons/CardGiftcard";
import Relocation from "@material-ui/icons/SwapHorizontalCircle";
import ServiceSettingsIcon from "@material-ui/icons/Tune";
import StoreIcon from "@material-ui/icons/Store";
import CampaignIcon from "@material-ui/icons/NotificationsNone";
import ReportsIcon from "@material-ui/icons/AssessmentOutlined";
import AppDrawerNavItem from "./AppDrawerNavItem";
import routePaths from "../routePaths";
import Tooltip from "@material-ui/core/Tooltip";
import logo from "../assets/roovee-logo.svg";
import amber from "@material-ui/core/colors/amber";
import { permissions, hasPermission } from "../utils/authorization";
import { ListItem } from "@material-ui/core";

/* eslint react/no-multi-comp: 0 */

const AppDrawer = ({
  open,
  docked,
  onRequestClose,
  areBikeRentalsRestricted,
  userPermissions,
}) => {
  let constKey = 0;
  const getNextKey = () => constKey++;

  const renderAlert = () => {
    if (areBikeRentalsRestricted)
      return (
        <Tooltip title="Wyłączono możliwość wypożyczania rowerów">
          <AlertIcon style={{ color: amber[500] }} />
        </Tooltip>
      );
    return null;
  };

  return (
    <Drawer
      open={open}
      onClose={onRequestClose}
      variant={docked ? "permanent" : "temporary"}
      classes={{ paper: "drawer" }}
    >
      <div>
        <Toolbar>
          <img src={logo} alt="roovee" style={{ width: "60%" }} />
          <Divider absolute />
        </Toolbar>
        <List style={{ padding: 0 }}>
          <AppDrawerNavItem
            key={getNextKey()}
            title="Pulpit"
            to={routePaths.home}
            onClick={onRequestClose}
            icon={<DashboardIcon />}
          />

          {hasPermission(userPermissions, permissions.bikes.list) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Rowery"
              to={routePaths.bikes}
              onClick={onRequestClose}
              icon={<DirectionsBike />}
              additionalIcon={renderAlert()}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.rides) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Przejazdy"
              to={routePaths.ridesReports}
              onClick={onRequestClose}
              icon={<Timeline />}
            />
          ) : null}
          {/*hasPermission(userPermissions, permissions.payoutFunds)
            ? <AppDrawerNavItem
              key={getNextKey()}
              title="Wypłaty środków"
              to={routePaths.payout}
              onClick={onRequestClose}
              icon={<Payment />}
            />
            : null*/}
          <ListItem disableGutters style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListSubheader>Serwis</ListSubheader>
          </ListItem>
          {hasPermission(userPermissions, permissions.bikes.list) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Lista rowerów"
              to={routePaths.service.bikeList}
              onClick={onRequestClose}
              icon={<DirectionsBike />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.bikes.alerts) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Mapa"
              to={routePaths.service.map}
              onClick={onRequestClose}
              icon={<Map />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.bikeDefects) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Zgłoszenia usterek"
              to={routePaths.bikeDefects}
              onClick={onRequestClose}
              icon={<Defect />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.relocation) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Relokacja"
              to={routePaths.relocationReport}
              onClick={onRequestClose}
              icon={<Relocation />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.adjustServiceSettings) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Konfiguracja"
              to={routePaths.service.settings}
              onClick={onRequestClose}
              icon={<ServiceSettingsIcon />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.adjustServiceSettings) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Magazyn"
              to={routePaths.service.warehouse}
              onClick={onRequestClose}
              icon={<StoreIcon />}
            />
          ) : null}
          {(hasPermission(userPermissions, permissions.rides) ||
            hasPermission(userPermissions, permissions.clients) ||
            hasPermission(userPermissions, permissions.reports.access)) && (
            <ListItem
              disableGutters
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <ListSubheader>Raporty</ListSubheader>
            </ListItem>
          )}
          {hasPermission(userPermissions, permissions.rides) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Raporty przejazdów"
              to={routePaths.ridesDetailsReportsPage}
              onClick={onRequestClose}
              icon={<Timeline />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.clients) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Użytkownicy"
              to={routePaths.clientsReport}
              onClick={onRequestClose}
              icon={<People />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.globalClients) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Globalni użytkownicy"
              to={routePaths.globalClientsReport}
              onClick={onRequestClose}
              icon={<People />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.reports.access) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Raporty"
              to={routePaths.reports}
              onClick={onRequestClose}
              icon={<ReportsIcon />}
            />
          ) : null}
          {hasPermission(
            userPermissions,
            permissions.termsAndConditions.access,
          ) ? (
            <ListItem
              disableGutters
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <ListSubheader>Konfiguracja</ListSubheader>
            </ListItem>
          ) : null}
          {hasPermission(
            userPermissions,
            permissions.termsAndConditions.access,
          ) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Regulamin"
              to={routePaths.termsAndConditions}
              onClick={onRequestClose}
              icon={<TermsIcon />}
            />
          ) : null}
          {hasPermission(
            userPermissions,
            permissions.pricingConfiguration.access,
          ) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Cenniki"
              to={routePaths.pricings}
              onClick={onRequestClose}
              icon={<PricingIcon />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.campaigns) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Kampanie"
              to={routePaths.campaigns}
              onClick={onRequestClose}
              icon={<CampaignIcon />}
            />
          ) : null}
          {hasPermission(
            userPermissions,
            permissions.pricingConfiguration.access,
          ) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Plany"
              to={routePaths.plans}
              onClick={onRequestClose}
              icon={<PlansIcon />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.manageCards) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Karty miejskie"
              to={routePaths.cards}
              onClick={onRequestClose}
              icon={<CardsIcon />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.zones) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Strefy"
              to={routePaths.zones}
              onClick={onRequestClose}
              icon={<Map />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.operatorAccounts) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Konta operatorów"
              to={routePaths.operators}
              onClick={onRequestClose}
              icon={<PermIdentity />}
            />
          ) : null}
          {hasPermission(userPermissions, permissions.notStaredRides) ? (
            <ListItem
              disableGutters
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <ListSubheader>DevTools</ListSubheader>
            </ListItem>
          ) : null}
          {hasPermission(userPermissions, permissions.notStaredRides) ? (
            <AppDrawerNavItem
              key={getNextKey()}
              title="Nierozpoczęte przejazdy"
              to={routePaths.notStartedRidesDetailsReportsPage}
              onClick={onRequestClose}
              icon={<Timeline />}
            />
          ) : null}
        </List>
      </div>
    </Drawer>
  );
};

AppDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  docked: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  areBikeRentalsRestricted: PropTypes.bool,
  userPermissions: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    areBikeRentalsRestricted: state.tenant.areBikeRentalsRestricted,
    userPermissions: state.auth.permissions,
  };
}

export default connect(mapStateToProps)(AppDrawer);
